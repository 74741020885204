<template>
  <div  class="content ma-0 mb-16 px-0 px-sm-16  mx-sm-12" >

    <div style="position: relative;" class="d-flex text-h5 font-weight-bold text-center mt-md-4 pt-8 pb-6" >
      
      <v-avatar class="ml-4 mt-0 "  size="100" v-if="userinfo.user_headimg">
        <v-img :src="imgdomain+userinfo.user_headimg"></v-img>
      </v-avatar>
      <div class="pl-8 pt-2 ">
        <div>{{userinfo.nick_name}}</div>
        <div class="grey--text text-subtitle-1 text-left">共发布{{topictotal}}篇推文</div>
        <div class="grey--text text-subtitle-1 text-left">{{userinfo.last_login_time_str}}</div>
      </div>

      <Share />
  
    </div>


    <v-container class="pa-0 ma-0 mt-3" >
      <v-row>
        <v-col class="mt-0" cols="6" md="4" v-for="item in topiclist" :key="item.topic_id" style="height:350px">
          <div class="topic-item d-flex grey lighten-3 flex-column justify-space-between">
            <div class="top-img pointer" @click="gotoDetail(item)">
              <v-img :src="imgdomain+item.image" max-height="170" :aspect-ratio="1/1"></v-img>
            </div>
            <div class="top-title topic-title pa-3 text-center" >{{item.title}}</div>
            <!--<div class="top-subtitle grey--text text-center pb-2" v-if="item.subtitle!=''">{{item.subtitle}}</div>-->
            <div class="top-subtitle grey--text text--darken-2 text-center pb-1">{{item.nick_name}}</div>
            <div class="text-center showdetailbtn">
              <v-btn  light plain :ripple=false  tile class="morebtn" @click="gotoDetail(item)">查看详情</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div  class="pa-5 py-md-12 text-center" v-if="topiclist.length<topictotal">
      <v-btn  dark outlined width="30%" tile class="main-btn" @click="update">加载更多内容</v-btn>
      <div><v-icon>mdi-chevron-down</v-icon></div>
    </div>


    <div id="bottomarea"></div>


  </div>
</template>

<script>

import topicApi from '@/api/topic'
import { mapState } from 'vuex'
import Share from '@/components/Share';


export default {

  components: {
      Share
  },
  data: () => ({
    uid : 0,
    shareImg :"",
    isLoaded:false,
    page : 2,
    topictotal:0,
    userinfo : null,
    topiclist : []

    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
      imgdomain: state => state.app.imgdomain,
      apidomain: state => state.app.apidomain,
      uid: state => state.user.uid,
    }),
    

    
  },


  mounted() {

    let uid = this.$route.query.uid
    this.uid = uid

    this.$util.setTitle("KOL专题")
    topicApi.koltopic({uid: uid, page_size:20}).then(res => {
      this.isLoaded = true
      this.topiclist = res.data.topic.data
      this.userinfo = res.data.member
      this.topictotal = res.data.topic.total
      
      this.$util.weixinShare(window.location.href, this.userinfo.nick_name)
      
    })
    
  },
  destroyed() {
    console.log("destroyed")
    //window.removeEventListener('scroll', this.scroll)
  },
  methods: {

    gotoDetail(item) {
      let query = {id:item.topic_id}
      
      this.$router.push( {
        path:'/topicdetail',
        query: query
      })
    },

    update() {
      this.isLoaded = false
      topicApi.koltopic({uid:this.uid, page_index:this.page,page_size:20}).then(res => {
        this.isLoaded = true
        this.page++
        
        res.data.topic.data.forEach((item) => {
          
            this.topiclist.push(item)
        })
      })
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.moretitle {
  font-size:1.1rem;
  border-bottom:1px solid black;
}
.topic-item {
  height: 100%;
}
/deep/ .showdetailbtn span{
  border-bottom:1px solid black;
  padding-bottom:0px;
  font-size: 0.8rem;
  font-weight: 400px !important;
  opacity:1 !important;
}
/deep/ .morebtn:active{
  opacity:0.5 !important;
}
</style>

<style lang="sass">


</style>